<template>
  <div @contextmenu="this.setContextIdWidget">
    <span class="hidden">{{ darkDark }}</span>
    <div
      :id="'gaugeWidget' + id"
      @drop="onDropOnWidgets($event, 1)"
      class="shadow-lg"
      :style="
        this.squareDisplay
          ? {
              display: 'flex',
              maxheight: '150px',
              width: this.borderDisplay
                ? parseInt(this.widgetWidth) + 'px'
                : parseInt(this.widgetWidth) + 'px',
              height: parseInt(this.widgetHeight) + 'px',
              borderRadius: '10px',
              borderStyle: 'solid',
              borderWidth: this.borderDisplay ? '2px' : '0px',
              boxShadow: this.borderDisplay
                ? '0 0 5px 3px var(--navBar2Bg)'
                : '',
              borderColor: this.darkDark !== 'white' ? 'white' : 'black',

              alignContent: 'center',
              overflow: 'hidden',
              background:
                !isNaN(this.alertStartRange) > 0 &&
                !isNaN(this.alertEndRange) > 0
                  ? this.blinkAlert &&
                    (parseFloat(this.alertStartRange) >
                      parseFloat(this.niddleAngle) ||
                      parseFloat(this.alertEndRange) <
                        parseFloat(this.niddleAngle))
                    ? this.newAlertColor
                    : this.backgroundColor
                  : this.backgroundColor,
              transition: 'var(--transition)',
            }
          : {
              display: 'flex',
              maxheight: '350px',
              top: 0,
              left: 0,
              width: this.borderDisplay
                ? parseInt(this.widgetWidth) + 'px'
                : parseInt(this.widgetWidth) + 'px',
              height: parseInt(this.widgetHeight) + 'px',

              borderRadius: '750px',
              // borderStyle: 'solid',
              boxShadow: this.borderDisplay
                ? this.darkDark !== 'white'
                  ? '0 0 5px 2px white'
                  : '0 0 5px 2px black'
                : '',
              // borderWidth: this.borderDisplay ? '2px' : '0px',
              // borderColor: this.darkDark !== 'white'  ? 'white' : 'black',
              overflow: 'hidden',
              background:
                !isNaN(this.alertStartRange) > 0 &&
                !isNaN(this.alertEndRange) > 0
                  ? this.blinkAlert &&
                    (parseFloat(this.alertStartRange) >
                      parseFloat(this.niddleAngle) ||
                      parseFloat(this.alertEndRange) <
                        parseFloat(this.niddleAngle))
                    ? this.newAlertColor
                    : this.backgroundColor
                  : this.backgroundColor,
              transition: 'var(--transition)',
            }
      "
    ></div>
  </div>
</template>

<script>
import {
  easeElasticOut,
  select,
  interpolateString,
  scaleLinear,
  arc,
  line,
} from "d3";
import apiService from "../../../api/services";
import { isNumber } from "util";
import { useReplay } from "../../../composable/useReplay.js";
import helperServices from "../../../helper-services";

var data = {
  soundurl: "http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3",
};
let id;
let svg;
let path;
let path2;
let middleCount, amount, total, percent, ratio, piePercent;
let innerRadius, outerRadius, w, h;
let tickLabelText = [];

export default {
  name: "GaugeWidget",
  components: {},
  props: {
    widgetId: String,
    displayId: String,
    isChanged: Boolean,
    blinkAlert: Boolean,
    squareDisplay: {
      type: Boolean,
      default: false,
    },
    alertColo: String,
    widgetWidth: {
      type: Number,
      default: 100,
    },
    widgetHeight: {
      type: Number,
      default: 100,
    },
    lightThemeDarkColor: {
      default: "#000000",
    },
    bgColor: {
      type: String,
      default: "var(--navBar2Bg)",
    },
    saveColor: {
      type: String,
      default: "var(--widgetBg)",
    },
    saveTitleColor: {
      type: String,
      default: "var(--textColor)",
    },
    saveNeedleColor: {
      type: String,
      default: "var(--textColor)",
    },
    saveValueColor: {
      type: String,
      default: "var(--textColor)",
    },
    unitColor: {
      type: String,
      default: "",
    },
    alertStartRange: {
      type: [Number, String],
    },
    alertEndRange: {
      type: [Number, String],
    },
    alertColor: {
      type: String,
      default: "red",
    },
    gaugeStartAngle: {
      type: String,
      default: "-30",
    },
    gaugeEndAngle: {
      type: String,
      default: "210",
    },
    rangeStart: {
      type: String,
      default: "0",
    },
    rangeEnd: {
      type: String,
      default: "300",
    },
    // niddleAngle: {
    //   type: Number,
    //   default: 124,
    // },
    arcCount: {
      type: String,
      default: "1",
    },
    gaugeBreakPoints: {
      type: Array,
      default: [{ value: 200, color: "#FF0000" }],
    },
    interval: {
      type: Number,
      default: 50,
    },
    borderDisplay: {
      type: Boolean,
      default: false,
    },
    valueDisplay: {
      type: Boolean,
      default: true,
    },
    valueDecimals: {
      type: Number,
      default: 2,
    },
    fontSize: {
      type: Number,
      default: 25,
    },
    labelFontPercent: {
      type: Number,
      default: 0.06,
    },

    titleDisplay: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    fullName: {
      type: String,
      default: "",
    },
    arc1Display: {
      type: Boolean,
      default: true,
    },
    arc1Color: {
      type: String,
      default: "#ff0000",
    },
    arc2Display: {
      type: Boolean,
      default: true,
    },

    openConfig: Function,
    gaugeWidgetConfig: {},
    arcLineColor: String,
    unitDisplay: {
      type: Boolean,
      default: true,
    },
    unit: {
      type: String,
      default: "",
    },
    isReplayEnable: {
      type: Boolean,
      default: false,
    },
    autoFit: {
      type: Boolean,
      default: true,
    },
    unit_conversion_factor: {
      type: [Number, String],
      default: "",
    },
  },

  data() {
    return {
      displayName: "",
      alertBlinker: null,
      logTypeId: "",
      newAlertColor: this.alertColor,
      id: "",
      isBlinkAlert: false,
      tempEndRange: this.rangeEnd,
      isPlayed: false,
      isRefresh: true,
      niddleAngle: "",
      gaugeConfig: this.gaugeWidgetConfig,
      values: [
        {
          lower: 0,
          upper: 1000,
          color: "#69B34C",
        },
        {
          lower: 38,
          upper: 1000,
          color: "#69B34C",
        },
        {
          lower: 100,
          upper: 1000,
          color: "#69B34C",
        },
        {
          lower: 3.5,
          upper: 6,
          color: "#FAB733",
        },
        {
          lower: 5000,
          upper: 10000,
          color: "#FF8E15",
        },
        {
          lower: 820,
          upper: 1000,
          color: "#ff6f00",
        },
        {
          lower: 1000,
          upper: 1000,
          color: "#FF0D0D",
        },
      ],
      defaultInterval: 50,
      defaultTitle: "",
      wellId: "",
      wellboreId: "",
      logId: "",
      status: "",
      logType: "",
      table: null,
      socket: null,
      backgroundColor: getComputedStyle(
        document.documentElement
      ).getPropertyValue("--widgetBg"),
      needleColor: this.saveNeedleColor || "#ffffff",
      valueTextColor: this.saveValueColor || "#ffffff",
      titleTextColor: this.saveTitleColor || "#ffffff",
      niddleAngleOriginal:"",
      // unit: "Unit"
    };
  },
  created() {
    // this.getRealtimeData();
  },
  computed: {
    darkDark() {
      this.$store.dispatch("rect/changeGaugeBgColor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: getComputedStyle(document.documentElement).getPropertyValue(
          "--widgetBg"
        ),
      });
      if (!this.saveColor) {
        if (this.$store.state.rect.darkmode) {
          this.$store.dispatch("rect/changeGaugeBgColor", {
            displayId: this.displayId,
            widgetId: this.widgetId,
            value: "#3f3f3f",
          });
        } else {
          this.$store.dispatch("rect/changeGaugeBgColor", {
            displayId: this.displayId,
            widgetId: this.widgetId,
            value: "#fdffe3",
          });
        }
      }

      if (!this.saveTitleColor) {
        if (this.$store.state.rect.darkmode) {
          this.titleTextColor = "#FFFFFF";
        } else {
          this.titleTextColor = "#000000";
        }
      }

      if (!this.saveNeedleColor) {
        if (this.$store.state.rect.darkmode) {
          this.needleColor = "#FFFFFF";
        } else {
          this.needleColor = "#000000";
        }
      }

      if (!this.saveValueColor) {
        if (this.$store.state.rect.darkmode) {
          this.valueTextColor = "#FFFFFF";
        } else {
          this.valueTextColor = "#000000";
        }
      }

      this.buildSVG1();

      return this.$store.state.rect.darkmode;
    },
  },

  sockets: {
    connect: function () {},
    disconnect() {
      this.isConnected = false;
    },
    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        fields: [this.defaultTitle || this.title],
        id: this.widgetId,
        clientId: this.$clientId,
      });
    },
  },

  methods: {
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    getRealtimeData(title) {
      // this.socket = io(URL.HOST_URL.SERVER_URL);

      let topic = this.topic;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];
      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let topicExists = this.$store.state.live.isSubscribed[topic];
        if (this.logId) {
          this.$socket.emit("subscribe", {
            logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
            topic: topic,
            fields: [this.defaultTitle || this.title],
            id: this.widgetId,
            clientId: this.$clientId,
          });
          if (!topicExists) {
            // if (this.logId) {
            //   this.$socket.emit("subscribe", {
            //     logId: this.logId,
            //     topic: topic,
            //     fields,
            //   });

            this.$store.dispatch("live/setSubscriptionFlag", {
              topic,
              flag: true,
            });
          }
        }

        topicExists = this.$store.state.live.isSubscribed[topic];
      }

      this.sockets.subscribe(this.$clientId, (sResult) => {
        let result = sResult[this.widgetId];

        if (result) {
          let value = result[this.defaultTitle] || result[this.title];
          if (value && value.length) {
            this.niddleAngle = value[value.length - 1];
            this.niddleAngleOriginal = value[value.length - 1];
            //check for conversion factor
            if (
              this.unit_conversion_factor &&
              this.unit_conversion_factor != ""
            ) {
              this.niddleAngle *= this.unit_conversion_factor;
            }
            this.getEndRange();
            this.buildSVG1();
          }
        }
      });
    },
    onDropOnWidgets(event) {
      let onDropDtls = JSON.parse(event.dataTransfer.getData("elProps"));
      // this.title = onDropDtls.title;
      event.stopPropagation();
      this.$store.dispatch("rect/changeGaugeTitle", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: onDropDtls.title,
        fullName: onDropDtls.fullName,
      });
      //
      this.$store.dispatch("rect/changeUnit", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: onDropDtls.units,
      });
      let displayDtls = this.$store.state.disp.displays[this.displayId];

      // this.displayName = onDropDtls.fullName;
      this.niddleAngle = "";
      this.defaultTitle = onDropDtls.title;
      if (displayDtls.status == "UPDATING") {
        // this.socket.disconnect();
        let topic = this.topic;
        this.sockets.unsubscribe(this.$clientId);
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });

        this.getRealtimeData(onDropDtls.title);
      }
      setTimeout(() => {
        this.getTagValue(displayDtls, onDropDtls.title);
      }, 300);
    },
    async getMnemonicType(mnemonic) {
      let logDetails = await helperServices.getIndexDetails(this.wellId);
      let all_mnemonics = logDetails.data.logs[this.logType].mnemonics;
      const source_mnemonice = logDetails.data.logs[this.logType].source;
      const tags = logDetails.data.logs[this.logType].tags;
      let mnemonic_type = "UNPREFERRED";
      const mnemonicKey = tags.findIndex((t) => t == mnemonic);
      const actualKey = source_mnemonice[mnemonicKey];
      for (let i in all_mnemonics) {
        if (all_mnemonics[i][actualKey]) {
          mnemonic_type = i;
          break;
        }
      }
      return mnemonic_type;
    },
    async getTagValue(displayDtls, title) {
      if (this.max && (title || this.title)) {
        let result = "";
        let mnemonic_type = await this.getMnemonicType(title || this.title);
        if (this.logType == "time")
          result = await apiService.WellServices.getSingleValueTimeLog({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            field: title || this.title,
            type: mnemonic_type,
            max: this.max,
            topic: this.topic,
          });
        else
          result = await apiService.WellServices.getSingleValueLog({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            field: title || this.title,
            type: mnemonic_type,
            max: this.max,
            topic: this.topic,
          });

        this.niddleAngle =
          parseFloat(result.data[0][title || this.title]) || this.niddleAngle;
        // if (this.niddleAngle > this.tempEndRange) {
        // this.tempEndRange =
        //   parseInt(this.niddleAngle + 100) -
        //   (parseInt(this.niddleAngle + 100) % 10);
        // this.defaultInterval = (this.tempEndRange - this.rangeStart) / 10;
        // }

        if (result.data[0][this.title] != null) {
          this.niddleAngle = result.data[0][this.title];
          this.niddleAngleOriginal = result.data[0][this.title];
        }
      }

      this.buildSVG1();
      return this.niddleAngle;
    },
    getEndRange() {
      // this.tempEndRange = 50;
      // if (
      //   parseFloat(this.niddleAngle) > parseFloat(this.tempEndRange) &&
      //   parseFloat(this.rangeEnd) < parseFloat(this.tempEndRange)
      // ) {
      //   this.tempEndRange =
      //     parseInt(this.niddleAngle + 100) -
      //     (parseInt(this.niddleAngle + 100) % 10);

      //   // this.defaultInterval = (this.tempEndRange - this.rangeStart) / 10;
      // } else if (
      //   parseFloat(this.rangeStart) + parseFloat(this.defaultInterval) <
      //     parseFloat(this.rangeEnd) &&
      //   parseFloat(this.rangeEnd) > parseFloat(this.niddleAngle)
      // ) {
      //   this.tempEndRange = this.rangeEnd;
      // } else {
      // }
      this.tempEndRange = this.rangeEnd;
      this.defaultInterval = (this.tempEndRange - this.rangeStart) / 10;
      console.log(
        "~~~~~~~~~ range start end ",
        this.tempEndRange,
        this.defaultInterval
      );
    },
    playSound() {
      this.isPlayed = true;
      var audio = new Audio(data.soundurl);
      audio.play();
      setTimeout(() => {
        //
        this.isPlayed = false;
      }, 12000);
    },
    clearSvgHandler: async () => {
      return new Promise((res, rej) => {
        //
        let gauge = select("#gaugeWidget" + this.id);
        gauge.selectAll("*").remove();
        svg = "";
        res();
      });
    },
    openModal() {
      this.$refs.modal.showModal();
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    buildSVG1(value1, value2) {
      let startAngleGauge = parseInt(this.gaugeStartAngle);
      let endAngleGauge = parseInt(this.gaugeEndAngle);

      //
      //

      tickLabelText = [];
      w = this.widgetWidth || 250;
      h = this.widgetHeight || 250;
      let originY = 0;
      let originX = 0;
      let outerRadius = w / 2 - 10;
      let innerRadius = w / 2 - 15;

      var color = [
        // this.gaugeWidgetConfig.arc1Color,
        // this.gaugeWidgetConfig.arc2Color,
      ];

      amount = value1;
      total = value2;
      percent = (amount / total) * 100;
      ratio = percent / 100;
      piePercent = (Math.PI + 0.4) * ratio;

      // Value responsible for ticks or scale.
      let minValue = parseInt(this.rangeStart) || 0;
      let maxValue = this.tempEndRange || 180;
      let tickStartAngel = startAngleGauge + 90; //67;
      let tickEndAngel = endAngleGauge + 90; //295;
      let needleAngle = 67;
      let needleVal = this.niddleAngle ? parseFloat(this.niddleAngle) : 0;

      let totalAngle = 240;

      if (parseInt(this.gaugeStartAngle) >= 0) {
        if (parseInt(this.gaugeEndAngle) >= 0) {
          totalAngle =
            parseInt(this.gaugeStartAngle) + parseInt(this.gaugeEndAngle);
        } else {
          totalAngle =
            parseInt(this.gaugeStartAngle) + parseInt(this.gaugeEndAngle) * -1;
        }
      } else {
        if (parseInt(this.gaugeEndAngle) >= 0) {
          totalAngle =
            parseInt(this.gaugeStartAngle) * -1 + parseInt(this.gaugeEndAngle);
        } else {
          totalAngle =
            parseInt(this.gaugeStartAngle) * -1 +
            parseInt(this.gaugeEndAngle) * -1;
        }
      }

      let arcCount = this.arcCount ? parseInt(this.arcCount) : 1;

      // startAngle -30, , endAngle 210

      let midAngle = totalAngle / arcCount; // 240 / total arc => midAngle 120
      if (startAngleGauge < 0) {
        midAngle += startAngleGauge;
      }
      svg = select("#gaugeWidget" + this.id);
      if (svg) {
        svg.selectAll("*").remove();
        //
        svg = "";
      } else {
        //
        let gauge = document.createElement("div");
        gauge.setAttribute("id", "gaugeWidget" + this.id);
      }

      svg = select("#gaugeWidget" + this.id)
        .append("svg")
        .attr("width", w)
        .attr("height", h)
        .style("transition", "var(--transition)")
        .append("g")
        .attr("transform", "translate(" + w / 2 + "," + h / 2 + ")");
      // .attr(
      //   "transform",
      //   this.borderDisplay
      //     ? "translate(" + w / 2 + "," + h / 2.05 + ")"
      //     : "translate(" + w / 1.99 + "," + h / 2.05 + ")"
      // );
      // console.log("******* consoling width and height of svg : ", w, h);
      let arcStart = startAngleGauge;
      let arcEnd = startAngleGauge + midAngle;

      let padding = 0.05,
        edgeWidth = 0.05,
        tickEdgeGap = 0.05,
        tickLengthMaj = 5,
        tickLengthMin = 3;

      let labelFontSize = 12;

      let tickStartMaj =
          outerRadius - padding - edgeWidth - tickEdgeGap - tickLengthMaj,
        tickStartMin =
          outerRadius - padding - edgeWidth - tickEdgeGap - tickLengthMin,
        labelStart = tickStartMaj - labelFontSize;
      if (labelStart < 90) {
        labelStart -= w * 0.05;
      }

      if (labelStart >= 90) {
        labelStart -= w * 0.08;
      }

      var valueScale = scaleLinear()
        .domain([minValue, maxValue])
        .range([tickStartAngel, tickEndAngel]);

      for (let i = 1; i <= arcCount; i++) {
        if (i == 1) {
          if (i == arcCount) {
            arcEnd = endAngleGauge;
          } else {
            if (this.gaugeBreakPoints[i - 1]) {
              arcEnd =
                valueScale(this.gaugeBreakPoints[i - 1].value) -
                valueScale(minValue - 3) +
                startAngleGauge -
                2;
            } else {
              /*if (this.gaugeWidgetConfig["arc" + i + "End"]) {
                arcEnd = parseInt(this.gaugeWidgetConfig["arc" + i + "End"]);
              } else {
              }*/
            }
          }
        } else if (i == arcCount) {
          /* if (this.gaugeWidgetConfig["arc" + i + "Start"]) {
            arcStart = parseInt(this.gaugeWidgetConfig["arc" + i + "Start"]);
          } else {
            arcStart = arcEnd;
          } */
          arcStart = arcEnd;
          arcEnd = endAngleGauge;
        } else {
          /* if (
            this.gaugeWidgetConfig["arc" + i + "Start"] &&
            this.gaugeWidgetConfig["arc" + i + "End"]
          ) {
            arcStart = parseInt(this.gaugeWidgetConfig["arc" + i + "Start"]);
            arcEnd = parseInt(arcStart + midAngle);
          } else {*/
          arcStart = arcEnd;
          if (
            this.gaugeBreakPoints[i - 1] &&
            this.gaugeBreakPoints[i - 1].value
          ) {
            arcEnd =
              valueScale(this.gaugeBreakPoints[i - 1].value) -
              valueScale(minValue - 3) +
              startAngleGauge;
          } else {
            arcEnd = arcStart + midAngle;
          }

          //}
        }
        let arca = arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(arcStart * (Math.PI / 180))
          .endAngle(arcEnd * (Math.PI / 180));
        // .cornerRadius(4);
        if (true) {
          path = svg
            .append("path")
            .attr("id", "path" + i + this.id) // assigning id so that can access later uniquely
            .attr("d", arca)
            .attr("transform", "rotate(-90)")
            .attr(
              "opacity",
              this.gaugeBreakPoints[i - 2] && this.gaugeBreakPoints[i - 2].color
                ? 1
                : 0
            )
            .attr(
              "fill",
              this.gaugeBreakPoints[i - 2]
                ? this.gaugeBreakPoints[i - 2].color
                : ""
            );
        }
      }

      let angleBetweenMajTicks = this.defaultInterval || 10;
      let angleBetweenMinorTicks =
        this.defaultInterval >= 10
          ? parseInt((this.defaultInterval || 10) / 10)
          : 1;

      // opt.padding=0.05;  opt.edgeWidth=0.05;  opt.tickEdgeGap=0.05;  opt.tickLengthMaj=0.15;  opt.tickLengthMin=0.05;
      // let tickStartMaj = outerRadius - opt.padding - opt.edgeWidth - opt.tickEdgeGap - opt.tickLengthMaj,

      var counter = 0,
        tickAnglesMaj = [],
        tickAnglesMin = [],
        tickSpacingMajDeg = valueScale(angleBetweenMajTicks) - valueScale(0),
        tickSpacingMinDeg = valueScale(angleBetweenMinorTicks) - valueScale(0);

      for (
        var i = tickStartAngel;
        i <= tickEndAngel;
        i = i + tickSpacingMajDeg
      ) {
        tickAnglesMaj.push(tickStartAngel + tickSpacingMajDeg * counter);
        counter++;
      }

      counter = 0;
      for (
        var i = tickStartAngel;
        i <= tickEndAngel;
        i = i + tickSpacingMinDeg
      ) {
        var exists = 0;
        tickAnglesMaj.forEach(function (d) {
          if (tickStartAngel + tickSpacingMinDeg * counter == d) {
            exists = 1;
          }
        });

        if (exists == 0) {
          tickAnglesMin.push(tickStartAngel + tickSpacingMinDeg * counter);
        }
        counter++;
      }

      counter = 0;

      for (
        var i = tickStartAngel;
        i <= tickEndAngel;
        i = i + tickSpacingMajDeg
      ) {
        tickLabelText.push(
          Math.round(minValue + angleBetweenMajTicks * counter)
        );

        counter++;
      }

      tickLabelText.push(Math.round(maxValue + angleBetweenMajTicks * 6));

      var pathTickMaj = tickCalcMaj();
      var pathTickMin = tickCalcMin();

      //Define two functions for calculating the coordinates of the major & minor tick mark paths
      function tickCalcMaj() {
        function pathCalc(d, i) {
          //Offset the tick mark angle so zero is vertically down, then convert to radians
          if (i == 0) {
            tickLengthMaj = tickLengthMaj + w * 0.02;
          } else if (i % 2) {
            tickLengthMaj = tickLengthMaj - w * 0.02;
          } else {
            tickLengthMaj = tickLengthMaj + w * 0.02;
          }
          var tickAngle = d + 90,
            tickAngleRad = dToR(tickAngle);

          var y1 = originY + (tickStartMaj + 5) * Math.sin(tickAngleRad),
            y2 =
              originY + (tickStartMaj - tickLengthMaj) * Math.sin(tickAngleRad),
            x1 = originX + (tickStartMaj + 5) * Math.cos(tickAngleRad),
            x2 =
              originX + (tickStartMaj - tickLengthMaj) * Math.cos(tickAngleRad),
            lineData = [
              { x: x1, y: y1 },
              { x: x2, y: y2 },
            ];

          var lineFunc = line()
            .x(function (d) {
              return d.x;
            })
            .y(function (d) {
              return d.y;
            });

          var lineSVG = lineFunc(lineData);

          return lineSVG;
        }
        return pathCalc;
      }

      function tickCalcMin() {
        function pathCalc(d, i) {
          //Offset the tick mark angle so zero is vertically down, then convert to radians
          var tickAngle = d + 90,
            tickAngleRad = dToR(tickAngle);

          var y1 = originY + tickStartMin * Math.sin(tickAngleRad),
            y2 =
              originY + (tickStartMin + tickLengthMin) * Math.sin(tickAngleRad),
            x1 = originX + tickStartMin * Math.cos(tickAngleRad),
            x2 =
              originX + (tickStartMin + tickLengthMin) * Math.cos(tickAngleRad),
            lineData = [
              { x: x1, y: y1 },
              { x: x2, y: y2 },
            ];

          var lineFunc = line()
            .x(function (d) {
              return d.x;
            })
            .y(function (d) {
              return d.y;
            });

          var lineSVG = lineFunc(lineData);

          return lineSVG;
        }
        return pathCalc;
      }

      function dToR(angleDeg) {
        //Turns an angle in degrees to radians
        var angleRad = angleDeg * (Math.PI / 180);
        return angleRad;
      }

      //Add a group to hold the ticks
      var ticks = svg.append("svg:g").attr("id", "tickMarks" + this.id);

      //Add a groups for major and minor ticks (minor first, so majors overlay)
      // let ticCol = this.darkDark !== 'white'  ? " #FFFFFF" : this.lightThemeDarkColor;
      let ticCol = getComputedStyle(document.documentElement).getPropertyValue(
        "--textColor"
      );
      var ticksMin = ticks
        .append("svg:g")
        .attr("id", "minorTickMarks" + this.id)
        .style("filter", "drop-shadow(" + ticCol + " 0px 0px 1px)");
      var ticksMaj = ticks
        .append("svg:g")
        .attr("id", "majorTickMarks" + this.id);

      var tickMin = ticksMin
        .selectAll("path")
        .data(tickAnglesMin)
        .enter()
        .append("path")
        .attr("d", pathTickMin)
        .style("stroke", ticCol)
        .style("stroke-width", "1px");

      var tickMaj = ticksMaj
        .selectAll("path")
        .data(tickAnglesMaj)
        .enter()
        .append("path")
        .attr("d", pathTickMaj)
        .style("stroke", ticCol)
        .style("stroke-width", "1.5px");
      // .style("filter", "drop-shadow("+ticCol+" 0px 0px 1px)")

      //Add labels for major tick marks
      var tickLabels = svg.append("svg:g").attr("id", "tickLabels" + this.id);
      var tickLabel = tickLabels
        .selectAll("text")
        .data(tickAnglesMaj)
        .enter()
        .append("text")
        .attr("x", (d, i) => {
          return labelXcalc(d, i);
        })
        .attr("y", (d, i) => {
          return labelYcalc(d, i);
        })
        .attr("font-size", this.widgetWidth * this.labelFontPercent)
        .attr("text-anchor", "middle")
        .style("fill", ticCol)
        .text(function (d, i) {
          return tickLabelText[i];
        })
        .attr("transform", "translate(-2,9)");

      //Define functions to calcuate the positions of the labels for the tick marks
      function labelXcalc(d, i) {
        let labelOffset = 0;
        if (i == 0) {
          labelOffset = -(w * 0.07);
        } else if (i % 2) {
          labelOffset = -(w * 0.03);
        } else {
          labelOffset = -(w * 0.07);
        }
        var tickAngle = d + 91,
          tickAngleRad = dToR(tickAngle),
          labelW = labelFontSize / (tickLabelText[i].toString().length / 2),
          x1 = originX + labelStart * Math.cos(tickAngleRad);

        return x1;
      }
      function labelYcalc(d, i) {
        let labelOffset = 0;
        if (i == 0) {
          labelOffset = -(w * 0.07);
        } else if (i % 2) {
          labelOffset = -(w * 0.02);
        } else {
          labelOffset = -(w * 0.07);
        }
        var tickAngle = d + 90,
          tickAngleRad = dToR(tickAngle),
          y1 = originY - 6 + labelStart * Math.sin(tickAngleRad);

        return y1;
      }

      let needleTickGap = 25,
        needleLengthPos =
          outerRadius -
          padding -
          edgeWidth -
          tickEdgeGap -
          tickLengthMaj -
          needleTickGap,
        needleLengthNeg = 10,
        needlePathStart = needleLengthNeg,
        needlePathLength = needleLengthNeg + needleLengthPos - 15;

      needleTickGap = needleTickGap * innerRadius;
      needleLengthNeg = needleLengthNeg * innerRadius;

      function percToRad(perc) {
        return dToR(perc * 360);
      }

      //Define a function for calculating the coordinates of the needle paths (see tick mark equivalent)
      function needleCalc() {
        function pathCalc(d, i) {
          var nAngleRad = dToR(d + 90);

          let needleG = svg
            .append("g")
            // .attr("transform", `translate(${width / 2}, ${height})`)
            .attr("fill", "#ffffff");

          let needleLen = 114;
          let needleRadius = 5;

          var y1 = originY + needlePathStart * Math.sin(nAngleRad),
            y2 =
              originY +
              (needlePathStart + needlePathLength) * Math.sin(nAngleRad),
            x1 = originX + needlePathStart * Math.cos(nAngleRad),
            x2 =
              originX +
              (needlePathStart + needlePathLength) * Math.cos(nAngleRad),
            lineData = [
              { x: x1, y: y1 - w * 0.002 },
              { x: x2, y: y2 },

              { x: 0, y: y1 + w * 0.002 },
              { x: 0, y: 0 },
            ];

          var lineFunc = line()
            .x(function (d) {
              return d.x;
            })
            .y(function (d) {
              return d.y;
            });

          var lineSVG = lineFunc(lineData);
          return lineSVG;
        }
        return pathCalc;
      }

      var pathNeedle = needleCalc();

      var needleGroup = svg.append("svg:g").attr("id", "needle" + this.id);

      //Draw the needle path
      var needlePath = needleGroup
        .selectAll("path")
        .data([tickStartAngel])
        .enter()
        .append("path")
        .attr("d", pathNeedle)
        .style("stroke", this.needleColor)
        .style("fill", this.needleColor)
        .style("stroke-width", "0.5px")
        .style("filter", "drop-shadow(" + ticCol + " 0px 0px 1px)");

      needlePath
        .transition()
        .duration(0)
        //.delay(0)
        .ease(easeElasticOut, 1, 0.9)
        //.attr("transform", function(d)
        .attrTween("transform", function (d, i, a) {
          needleAngle = valueScale(needleVal);

          //Check for min/max ends of the needle
          if (needleAngle > tickEndAngel) {
            needleAngle = tickEndAngel;
          }
          if (needleAngle < tickStartAngel) {
            needleAngle = tickStartAngel;
          }
          var needleCentre = originX + "," + originY,
            needleRot = needleAngle - tickStartAngel;
          return interpolateString(
            "rotate(" + needleRot + needleCentre + ")",
            "rotate(" + needleRot + "," + needleCentre + ")"
          );
        });

      //Add a group to hold the needle path

      var circleGroup = svg.append("svg:g").attr("id", "circles" + this.id);
      var pivotC = circleGroup
        .append("svg:circle")
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", this.widgetWidth > 290 ? 24 : this.widgetWidth * 0.08)
        .style("fill", "#999")
        .style("stroke", "none")
        .style("filter", "drop-shadow(" + ticCol + " 0px 0px 1px)");

      if (this.titleDisplay) {
        middleCount = svg
          .append("text")
          .attr("x", 0)
          .attr("y", this.widgetWidth / 3.3)
          .text(this.displayName?.toUpperCase() || "")
          .attr("text-anchor", "middle")
          .style("font-size", this.fontSize - 2 + "px")
          .style("font-weight", "bold")
          .style("fill", this.titleTextColor);
        // console.log('middleCount.node().getComputedTextLength()', middleCount.node()?.getBoundingClientRect().width)
        if (
          this.autoFit &&
          this.displayName &&
          middleCount.node()?.getBoundingClientRect().width >
            this.widgetWidth - (this.widgetWidth * 23) / 100
        ) {
          let textWidth = middleCount.node()?.getBoundingClientRect().width;
          middleCount.style(
            "font-size",
            ((this.widgetWidth - (this.widgetWidth * 23) / 100) / textWidth) *
              this.fontSize -
              2 +
              "px"
          );
        }
      }

      if (this.valueDisplay) {
        middleCount = svg
          .append("text")
          .attr("x", 0)
          .attr("y", this.widgetWidth / 3.3 + this.fontSize)
          .text(
            this.niddleAngle
              ? parseFloat(this.niddleAngle).toFixed(this.valueDecimals)
              : typeof this.niddleAngle == "string"
              ? ""
              : 0
          )
          .attr("text-anchor", "middle")
          .style("font-size", this.fontSize - 5 + "px")
          .style("fill", this.valueTextColor);
      }

      if (this.unitDisplay) {
        middleCount = svg
          .append("text")
          .attr("x", 0)
          .attr("y", this.widgetWidth / 3.3 + this.fontSize * 2)
          .text(this.unit?.toUpperCase())
          .attr("text-anchor", "middle")
          .style("font-size", this.fontSize - 5 + "px")
          .style(
            "fill",
            this.unitColor
              ? this.unitColor
              : getComputedStyle(document.documentElement).getPropertyValue(
                  "--textColor"
                )
          );
      }
    },
    tickCalcMaj() {
      function pathCalc(d, i) {
        //Offset the tick mark angle so zero is vertically down, then convert to radians
        var tickAngle = d + 90,
          tickAngleRad = dToR(tickAngle);

        var y1 = 0 + outerRadius * Math.sin(tickAngleRad),
          y2 = 0 + (outerRadius - 5 + -8) * Math.sin(tickAngleRad),
          x1 = 0 + outerRadius * Math.cos(tickAngleRad),
          x2 = 0 + (outerRadius - 5 + -8) * Math.cos(tickAngleRad),
          lineData = [
            { x: x1, y: y1 },
            { x: x2, y: y2 },
          ];

        var lineFunc = line()
          .x(function (d) {
            return d.x;
          })
          .y(function (d) {
            return d.y;
          });

        var lineSVG = lineFunc(lineData);

        return lineSVG;
      }
      function dToR(angleDeg) {
        //Turns an angle in degrees to radians
        var angleRad = angleDeg * (Math.PI / 180);
        return angleRad;
      }
      return pathCalc;
    },
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.id = i;
      }
    }
  },
  beforeUnmount() {
    let topic = this.topic;

    if (this.$socket) {
      this.sockets.unsubscribe(this.$clientId);
      let tempId = this.widgetId;

      this.$socket.emit("unsubscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: topic,
        id: tempId,
        clientId: this.$clientId,
      });
    }
    if (!this.isReplayEnable) {
      this.gaugeWatcher();
    }
    if (this.alertBlinker) {
      clearInterval(this.alertBlinker);
      this.newAlertColor = this.alertColor;
    }
  },
  mounted() {
    var i = -1;

    let values = JSON.parse(JSON.stringify(this.values));

    let displayDtls = this.$store.state.disp.displays[this.displayId];
    if (displayDtls) {
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.max = displayDtls.max;
      this.logId = displayDtls.logId;
      this.status = displayDtls.status;
      this.logType = displayDtls.logType;
      this.logTypeId = displayDtls.logTypeId;
      this.table = displayDtls.table;
      this.displayName = this.fullName;
      this.niddleAngle = "";
      this.defaultTitle = this.title;
      this.topic = displayDtls.topicName;
      if (this.status == "UPDATING") {
        let topic = displayDtls.topicName;

        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
        setTimeout(this.getRealtimeData, 200);
      }
      setTimeout(() => {
        this.getTagValue(displayDtls, this.defaultTitle);
      }, 300);
    }

    this.getEndRange();
    if (this.alertBlinker) {
      clearInterval(this.alertBlinker);
      this.newAlertColor = this.alertColor;
    }
    if (this.blinkAlert) {
      this.isBlinkAlert = true;
      let i = 0;
      this.alertBlinker = setInterval(() => {
        let color = [this.backgroundColor, this.alertColor];
        i = (i + 1) % color.length;
        this.newAlertColor = color[i];
        if (!this.isBlinkAlert) {
          clearInterval(this.isBlinkAlert);
          this.newAlertColor = this.alertColor;
        }
      }, 1000);
    }

    this.buildSVG1(values[3].lower, values[3].upper, "#00ff00");
    let value;
    let valueAt = "end";

    if (!this.isReplayEnable) {
      this.gaugeWatcher = this.$store.subscribe((mutation, state) => {
        //
        if (mutation.type == "data/setSingleValue") {
          let display = this.$store.state.disp.displays[this.displayId];

          let valueAt = "end";
          let name =
            this.title +
            "-" +
            this.displayId +
            "-" +
            display.logId +
            "-" +
            valueAt;

          let plName =
            mutation.payload.title +
            "-" +
            mutation.payload.display +
            "-" +
            mutation.payload.logId +
            "-" +
            mutation.payload.valueAt;

          if (name == plName) {
            this.niddleAngle = mutation.payload.value;
            this.getEndRange();
          }
          this.buildSVG1(values[3].lower, values[3].upper, "#00ff00");
        }
        if (
          mutation.type == "disp/setDisplay" &&
          mutation.payload.display == this.displayId
        ) {
          if (this.$socket) {
            let topic = this.topic;

            this.$socket.emit("unsubscribe", {
              logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
              topic: topic,
              id: this.widgetId,
              clientId: this.$clientId,
            });
            this.sockets.unsubscribe(this.$clientId);
          }
          this.wellId = mutation.payload.wellId;
          this.wellboreId = mutation.payload.wellboreId;
          this.max = mutation.payload.max;
          this.logId = mutation.payload.logId;
          this.status = mutation.payload.status;
          this.logType = mutation.payload.logType;
          this.logTypeId = mutation.payload.logTypeId;
          this.table = mutation.payload.table;
          let displayDtls = this.$store.state.disp.displays[this.displayId];
          this.displayName = this.fullName;
          this.niddleAngle = "";
          this.defaultTitle = this.title;
          this.topic = mutation.payload.topicName;
          if (this.status == "UPDATING") {
            setTimeout(this.getRealtimeData, 200);
          }
          // else {

          setTimeout(() => {
            this.getTagValue(mutation.payload, this.defaultTitle);
          }, 300);
          this.buildSVG1(values[3].lower, values[3].upper, "#00ff00");
        }
        if (mutation.type == "data/displayChanged") {
          let valueAt = "end";

          let name =
            this.title +
            "-" +
            this.displayId +
            "-" +
            this.logId +
            "-" +
            valueAt;

          if (name == mutation.payload) {
            this.niddleAngle = this.$store.state.data[name].value;
            this.getEndRange();
          }
          this.buildSVG1(values[3].lower, values[3].upper, "#00ff00");
          // this.niddleAngle = this.$store.state.data[name].value;
        }

        if (mutation.type == "disp/setTags") {
          if (mutation.payload.details.display == this.displayId) {
            let unit = this.title
              ? this.$store.state.disp.displays[this.displayId].units[
                  this.$store.state.disp.displays[this.displayId].tags.indexOf(
                    this.title.toLowerCase()
                  )
                ]
              : "";

            this.$store.dispatch("rect/changeUnit", {
              displayId: this.displayId,
              widgetId: this.widgetId,
              value: unit,
            });
          }
        }
        if(mutation.type == "disp/MINIMIZE_DISP" && this.displayId== mutation.payload.displayId){  
          this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      }
      if(mutation.type == "disp/MAXIMIZE_DISP" && this.displayId== mutation.payload.displayId){
        this.getRealtimeData();
      }
      });
    }
  },
  watch: {
    unit(newValue, value2) {
      this.buildSVG1();
    },
    isChanged: async function (newVal, oldVal) {
      //
      let values = JSON.parse(JSON.stringify(this.values));
      this.buildSVG1(values[3].lower, values[3].upper, "#00ff00");
    },
    gaugeBreakPoints: async function (newVal, oldVal) {
      try {
        this.buildSVG1();
      } catch (error) {
        //
      }
    },
    widgetWidth: async function (newVal, oldVal) {
      try {
        let values = JSON.parse(JSON.stringify(this.values));
        this.buildSVG1(values[3].lower, values[3].upper, "#00ff00");
      } catch (error) {
        //
      }
    },
    gaugeStartAngle: async function (newVal, oldVal) {
      if (
        this.gaugeStartAngle != this.gaugeEndAngle &&
        this.gaugeEndAngle > this.gaugeStartAngle
      ) {
        this.buildSVG1();
      }
    },
    gaugeEndAngle: async function (newVal, oldVal) {
      if (
        this.gaugeStartAngle != this.gaugeEndAngle &&
        this.gaugeEndAngle > this.gaugeStartAngle
      ) {
        this.buildSVG1();
      }
    },
    title: async function (newVal, oldVal) {
      try {
        //
        let displayDtls = this.$store.state.disp.displays[this.displayId];
        let unit = Object.keys(
          this.$store.state.disp.displays[this.displayId]
        ).includes("units")
          ? this.$store.state.disp.displays[this.displayId].units[
              this.$store.state.disp.displays[this.displayId].tags.indexOf(
                newVal.toLowerCase()
              )
            ]
          : this.unit;

        this.$store.dispatch("rect/changeUnit", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: unit,
        });
        this.niddleAngle = "";
        this.defaultTitle = newVal.toLowerCase();
        if (displayDtls.status == "UPDATING") {
          let topic = this.topic;

          this.$socket.emit("unsubscribe", {
            logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
            topic: topic,
            id: this.widgetId,
            clientId: this.$clientId,
          });
          this.sockets.unsubscribe(this.$clientId);

          this.getRealtimeData();
        }
        // else {
        setTimeout(() => {
          this.getTagValue({}, newVal.toLowerCase());
        }, 300);
        this.buildSVG1();
      } catch (error) {
        //
      }
    },
    fullName(newVal) {
      this.displayName = newVal;
    },
    titleDisplay: async function (newVal, oldVal) {
      this.buildSVG1();
    },
    valueDecimals: async function (newVal, oldVal) {
      this.buildSVG1();
    },
    rangeStart: async function (newVal, oldVal) {
      if (this.tempEndRange > parseInt(newVal) + 40) {
        this.getEndRange();
        this.buildSVG1();
      }
    },

    rangeEnd: async function (newVal, oldVal) {
      if (newVal > parseInt(this.rangeStart)) {
        this.getEndRange();
        this.buildSVG1();
      }
    },
    // interval: async function (newVal, oldVal) {
    //   if (newVal > 9 && newVal < this.tempEndRange) {
    //     this.defaultInterval = newVal;
    //     this.getEndRange();
    //     this.buildSVG1();
    //   }
    // },

    saveValueColor: async function (newVal, oldVal) {
      this.valueTextColor = newVal;
      this.buildSVG1();
    },
    unitColor: async function (newVal, oldVal) {
      this.buildSVG1();
    },
    saveColor: function (newVal, oldVal) {
      this.backgroundColor = newVal;
      this.buildSVG1();
    },
    saveTitleColor: function (newVal, oldVal) {
      this.titleTextColor = newVal;
      this.buildSVG1();
    },
    saveNeedleColor: function (newVal, oldVal) {
      this.needleColor = newVal;
      this.buildSVG1();
    },
    bgColor: function (newVal, oldVal) {
      this.backgroundColor = newVal;
      this.buildSVG1();
    },
    alertStartRange: async function (newVal, oldVal) {
      if (
        this.niddleAngle > this.alertStartRange &&
        this.niddleAngle < this.alertEndRange
      ) {
        this.getEndRange();
        this.buildSVG1();
      }
    },
    alertEndRange: async function (newVal, oldVal) {
      if (this.alertBlinker) {
        clearInterval(this.alertBlinker);
        this.newAlertColor = this.alertColor;
      }
      console.log(" in blink alert watcher 2");
      let i = 0;
      if (newVal) {
        console.log(" in blink alert watcher3");
        this.isBlinkAlert = true;
        let i = 0;
        this.alertBlinker = setInterval(() => {
          let color = [this.backgroundColor, this.alertColor];
          i = (i + 1) % color.length;
          console.log(" in blink alert watcher4 ", color[i]);
          this.newAlertColor = color[i];
          if (!this.isBlinkAlert) {
            clearInterval(this.isBlinkAlert);
            this.newAlertColor = this.alertColor;
          }
        }, 1000);
      }
      if (
        this.niddleAngle > this.alertStartRange &&
        this.niddleAngle < this.alertEndRange
      ) {
        this.getEndRange();
        this.buildSVG1();
      }
    },

    blinkAlert: function (newVal) {
      if (Number(this.alertStartRange) && Number(this.alertEndRange)) {
        this.isBlinkAlert = newVal;
        console.log(
          " in blink alert watcher",
          newVal,
          isNumber(this.alertStartRange),
          isNumber(this.alertEndRange)
        );

        if (this.alertBlinker) {
          clearInterval(this.alertBlinker);
          this.newAlertColor = this.alertColor;
        }
        console.log(" in blink alert watcher 2");
        let i = 0;
        if (newVal) {
          console.log(" in blink alert watcher3");
          this.isBlinkAlert = true;
          let i = 0;
          this.alertBlinker = setInterval(() => {
            let color = [this.backgroundColor, this.alertColor];
            i = (i + 1) % color.length;
            console.log(" in blink alert watcher4 ", color[i]);
            this.newAlertColor = color[i];
            if (!this.isBlinkAlert) {
              clearInterval(this.isBlinkAlert);
              this.newAlertColor = this.alertColor;
            }
          }, 1000);
        }
      }

      this.buildSVG1();
    },

    alertColor: async function (newVal, oldVal) {
      this.newAlertColor = newVal;
      if (this.alertBlinker) {
        clearInterval(this.alertBlinker);
      }

      let i = 0;
      if (this.isBlinkAlert) {
        this.alertBlinker = setInterval(() => {
          let color = [this.backgroundColor, newVal];
          i = (i + 1) % color.length;
          this.newAlertColor = color[i];

          if (!this.isBlinkAlert && this.isBlinkAlert) {
            clearInterval(this.isBlinkAlert);
            this.newAlertColor = this.alertColor;
          }
        }, 1000);
      }
      this.buildSVG1();
    },

    labelFontPercent: async function (newVal, oldVal) {
      if (this.tempEndRange > parseInt(this.rangeStart) + 40) this.buildSVG1();
    },
    gaugeWidgetConfig: async function (newVal, oldVal) {
      //
      let values = JSON.parse(JSON.stringify(this.values));
      this.valueDecimals = newVal.valueDecimals;

      // if (this.gaugeWidgetConfig.rangeEnd != newVal.niddleAngle + 10) {
      this.tempEndRange =
        newVal.niddleAngle > newVal.rangeEnd
          ? newVal.niddleAngle + 10
          : newVal.rangeEnd;
      this.interval = parseInt(this.tempEndRange / 200) * 10;
      this;
      // }

      await this.clearSvgHandler();

      if (newVal.rangeEnd > newVal.rangeStart) {
        this.buildSVG1(values[3].lower, values[3].upper, "#00ff00");
      }
      if (
        this.niddleAngle > this.alertStartRange &&
        this.niddleAngle < this.alertEndRange &&
        this.playAlarmSound &&
        !this.isPlayed
      ) {
        //
        this.playSound();
      }
    },
    replayDetails(result) {
      let value = result[this.title];
      if (value && value.length) {
        this.niddleAngle = value[value.length - 1];

        this.getEndRange();
        this.buildSVG1();
      }
    },
    unit_conversion_factor(val){
      if(val!=''){
        let tempVal = +this.niddleAngle;
        if(val==1){
          tempVal = +this.niddleAngleOriginal;
        }
        const withfactVal = tempVal * val;
        this.niddleAngle = withfactVal;
        this.buildSVG1();
      }
    },
  },
  setup() {
    const { replayDetails } = useReplay();
    return {
      replayDetails,
    };
  },
};
</script>
